import React from "react";
import theme from "theme";
import { Theme, Text, Box, Button, Image, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Homeplus Centum
			</title>
			<meta name={"description"} content={"At Homeplus Centum ATV Rentals, we invite you to embark on an unforgettable journey. "} />
			<meta property={"og:title"} content={"Home | Homeplus Centum"} />
			<meta property={"og:description"} content={"At Homeplus Centum ATV Rentals, we invite you to embark on an unforgettable journey. "} />
			<meta property={"og:image"} content={"https://pro.sumswind.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://pro.sumswind.com/img/2077056.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://pro.sumswind.com/img/2077056.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://pro.sumswind.com/img/2077056.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://pro.sumswind.com/img/2077056.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://pro.sumswind.com/img/2077056.png"} />
			<meta name={"msapplication-TileImage"} content={"https://pro.sumswind.com/img/2077056.png"} />
			<meta name={"msapplication-TileColor"} content={"https://pro.sumswind.com/img/2077056.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="Hero-16">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="0px 80px 0px 0px"
					lg-padding="0px 0 0px 0px"
					lg-margin="0px 0px 50px 0px"
					align-items="flex-start"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						padding="7px 20px 7px 20px"
						margin="0px 0px 30px 0px"
						border-radius="25px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						sm-flex-direction="column"
					>
						<Text
							margin="0px 10px 0px 0px"
							color="#6ad394"
							font="normal 400 16px/1.5 --fontFamily-sans"
							sm-margin="0px 0 5px 0px"
							border-width="0 1px 0 0"
							border-style="solid"
							padding="0px 10px 0px 0px"
							border-color="#6ad394"
							sm-border-width={0}
						>
							ATV Rentals
						</Text>
						<Text
							margin="0px 10px 0px 0px"
							color="#6ad394"
							font="normal 400 16px/1.5 --fontFamily-sans"
							padding="0px 10px 0px 0px"
							border-color="#6ad394"
							sm-border-width={0}
						>
							Your gateway to an exhilarating off-road experience
						</Text>
					</Box>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Homeplus Centum
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
						Here you'll find answers to some of the most common questions about our services, rentals, and policies. We aim to provide all the information you need for an amazing off-road adventure. If you have additional questions, please don't hesitate to reach out us.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-self="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="#6ad394"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="#6ad394"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							sm-width="100%"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
							sm-text-align="center"
							sm-align-self="center"
							sm-display="inline-block"
						>
							Contact Us
						</Button>
					</Box>
				</Box>
				<Image
					src="https://pro.sumswind.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Why Choose Homeplus Centum?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
					max-width="480px"
				>
					Our convenient location gives you access to some of the most stunning trails around, perfect for everyone from novices to seasoned riders. Whether you're navigating challenging terrains or enjoying a leisurely ride through picturesque scenery, Homeplus Centum has you covered. We take pride in our excellent customer service and strive to create unforgettable experiences for all our guests.
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Top-Notch Equipment
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Our fleet of ATVs features the latest models, ensuring a reliable and exciting ride. Each vehicle is equipped with advanced safety features and is regularly updated.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Expert Assistance
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Our friendly and knowledgeable staff is always ready to provide you with the information and support you need. From riding tips to trail recommendations, we're here to help.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Beautiful Trails
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Discover breathtaking views and hidden spots with our well-planned trails. We offer routes for every skill level, from easy paths to more challenging tracks.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px" quarkly-title="Advantages/Features-24">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					lg-width="100%"
				>
					Ready to Explore?
				</Text>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Begin your adventure with Homeplus Centum ATV Rentals today! Embrace your inner adventurer and create lasting memories with us. Book your ATV now and set off on an exciting journey!
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Homeplus Centum ATV Rentals is your gateway to an exhilarating off-road experience. Let's get rolling!
						</Text>
					</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://pro.sumswind.com/img/2.jpg"
					max-width="400px"
					border-radius="8px"
					height="100%"
					object-fit="cover"
					lg-width="100%"
					lg-max-width="none"
				/>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});